import BillingFuelCostTable from '@/sections/billing/billing-fuel-cost-table'
import BillingHeatingCostTable from '@/sections/billing/billing-heating-cost-table'
import { Stack } from '@mui/material'
import BillingAdditionalCostTable from '@/sections/billing/billing-additional-cost-table'
import BillingTenantDataTable from '@/sections/billing/billing-tenant-data-table'
import BillingManualDevicesTable from '@/sections/billing/billing-manual-devices-table'
import BillingControlTable from '@/sections/billing/billing-control-table'
import BillingSubmitPanel from '@/sections/billing/billing-submit-panel'
import { usePage } from '@inertiajs/react'
import { useEffect, useState } from 'react'
import BillingState from '@/constants/BillingState'

export default function BillingDetailTransmission() {
  const { billing } = usePage().props

  const forceOpen = billing.state === BillingState.DONE || billing.state === BillingState.SUBMITTED
  const [expandedTable, setExpandedTable] = useState(null)
  const [editForbidden, setEditForbidden] = useState(
    billing.state === BillingState.DONE || billing.state === BillingState.SUBMITTED
  )
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    setEditForbidden(billing.state === BillingState.DONE || billing.state === BillingState.SUBMITTED)
  }, [billing.state])

  const handleExpand = (cardId) => {
    setExpandedTable((prev) => (prev === cardId ? null : cardId))
  }

  return (
    <Stack direction={'column'} gap={3}>
      <BillingFuelCostTable
        expanded={expandedTable === 'fuelCost' || forceOpen}
        onExpand={() => handleExpand('fuelCost')}
        editForbidden={editForbidden}
      />
      <BillingHeatingCostTable
        expanded={expandedTable === 'heatingCost' || forceOpen}
        onExpand={() => handleExpand('heatingCost')}
        editForbidden={editForbidden}
      />
      <BillingAdditionalCostTable
        expanded={expandedTable === 'additionalCost' || forceOpen}
        onExpand={() => handleExpand('additionalCost')}
        editForbidden={editForbidden}
      />
      <BillingTenantDataTable
        expanded={expandedTable === 'tenantData' || forceOpen}
        onExpand={() => handleExpand('tenantData')}
        editForbidden={editForbidden}
        errors={errors}
      />
      <BillingManualDevicesTable
        expanded={expandedTable === 'manualDevices' || forceOpen}
        onExpand={() => handleExpand('manualDevices')}
        editForbidden={editForbidden}
      />
      <BillingControlTable
        expanded={expandedTable === 'control' || forceOpen}
        onExpand={() => handleExpand('control')}
        editForbidden={editForbidden}
      />
      <BillingSubmitPanel setErrors={setErrors} />
    </Stack>
  )
}
