import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import Scrollbar from '@/components/scrollbar'
import Iconify from '@/components/iconify'
import IconButton from '@mui/material/IconButton'
import DocumentUpdateModal from '@/sections/document/document-update-modal'
import { useModalContext } from '@/context/use-modal-context'
import Box from '@mui/material/Box'
import { trans } from '@/utils/translation'
import DocumentManagerInvitedItem from '@/sections/document/document-manager-invited-item'
import Button from '@mui/material/Button'
import { hasRole } from '@/utils/misc'
import { usePage } from '@inertiajs/react'
import Roles from '@/constants/Roles'

// ----------------------------------------------------------------------

export default function DocumentManagerDetails({ item, open, close, setRefresh }) {
  const { auth } = usePage().props
  const { openModal } = useModalContext()

  const renderShared = (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
        <Typography variant="subtitle2"> {trans('document.share')} </Typography>

        <IconButton
          disabled={item.createdBy?.id !== auth.user.id && !hasRole(auth.user, Roles.ROLE_ADMIN)}
          size="small"
          color="primary"
          onClick={() =>
            openModal('documentUpdate', <DocumentUpdateModal documentId={item.id} setRefresh={setRefresh} />)
          }
          sx={{
            width: 24,
            height: 24,
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            '&:hover': {
              bgcolor: 'primary.dark'
            }
          }}
        >
          <Iconify icon="mingcute:add-line" />
        </IconButton>
      </Stack>

      <Box sx={{ pl: 2.5, pr: 1 }}>
        {item.users && (
          <Box sx={{ maxHeight: '70dvh', overflow: 'auto' }}>
            {item.users.map((user) => (
              <Stack key={user.id}>
                <DocumentManagerInvitedItem setRefresh={setRefresh} label={user.name} item={user} document={item} />
              </Stack>
            ))}
          </Box>
        )}
      </Box>
    </>
  )

  return (
    <>
      <Drawer
        open={open}
        onClose={close}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true }
        }}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Typography variant="h6"> Info </Typography>
          </Stack>

          <Typography variant="subtitle1" sx={{ wordBreak: 'break-all', p: 2.5, bgcolor: 'background.neutral' }}>
            {item.name}
          </Typography>

          <Stack spacing={2} direction={'row'} sx={{ px: 2.5, pb: 2, bgcolor: 'background.neutral' }}>
            <Button
              startIcon={<Iconify icon={'eva:eye-outline'} />}
              variant={'contained'}
              onClick={() => window.open(`/documents/${item.id}/view`, '_blank')}
            >
              <Typography variant={'body'}>{trans('button.show')}</Typography>
            </Button>
            <Button
              startIcon={<Iconify icon={'eva:download-outline'} />}
              variant={'contained'}
              onClick={() => (document.location.href = `/documents/${item.id}/download`)}
            >
              <Typography variant={'body'}>{trans('button.download')}</Typography>
            </Button>
          </Stack>

          {renderShared}
        </Scrollbar>
      </Drawer>
    </>
  )
}

DocumentManagerDetails.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.object,
  close: PropTypes.func,
  setRefresh: PropTypes.func
}
