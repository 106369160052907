import { Card, Stack, Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import { router, usePage } from '@inertiajs/react'
import { alpha, useTheme } from '@mui/material/styles'
import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import PropertyStateLabel from '../property-state-label'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { bgGradient } from '@/theme/css'
import Button from '@mui/material/Button'
import PropertyDetailPage from '../property-detail-page'
import OwnerListView from '../../owner/view/owner-list-view'
import { TenantListView } from '@/sections/tenant/view'
import { DocumentListView } from '@/sections/document/view'
import { DeviceListView } from '@/sections/device/view'
import { ApartmentListView } from '@/sections/apartment/view'
import { EnergySavingsLeaf } from '@mui/icons-material'
import InfoView from '@/components/info-view'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import { ManagerListView } from '@/sections/manager/view'
import VideoModal from '@/components/modal/video-modal'
import { useModalContext } from '@/context/use-modal-context'

export default function PropertyDetailView() {
  const { property, auth } = usePage().props
  const { openModal } = useModalContext()

  const theme = useTheme()
  const [currentTab, setCurrentTab] = useState('data')

  const TABS = [
    {
      value: 'data',
      label: trans('property.tabs.data')
    },
    {
      value: 'units',
      label: trans('property.tabs.units')
    },
    {
      value: 'tenants',
      label: trans('property.tabs.tenant')
    },
    {
      value: 'owners',
      label: trans('property.tabs.owner'),
      role: Roles.ROLE_PROPERTY_ADMIN
    },
    {
      value: 'managers',
      label: trans('property.tabs.manager'),
      role: Roles.ROLE_ADMIN
    },
    {
      value: 'documents',
      label: trans('property.tabs.documents')
    },
    {
      value: 'devices',
      label: trans('property.tabs.devices')
    }
  ]

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('property.header')}
      </Typography>

      <Stack direction={'row'} spacing={2} flexWrap={'wrap'}>
        <Card
          sx={{
            mb: 3,
            height: 290,
            minWidth: { xs: '100%', md: '650px', lg: '800px', xl: '850px' },
            flexGrow: 10
          }}
        >
          <Box
            sx={{
              height: 1,
              color: 'common.white',
              ...bgGradient({
                direction: 'to right',
                color: alpha(theme.palette.primary.lighter, 0.1),
                imgUrl: require('../../../../images/Cover/Liegenschaft.jpg'),
                startColor: alpha(theme.palette.primary.darker, 0.4)
              })
            }}
          >
            <Stack direction={'column'} justifyContent={'space-between'} height={'80%'}>
              <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'end'} justifyContent={'space-between'} p={4}>
                {/* <Iconify icon="solar:camera-add-bold" width={32} /> */}
                <div></div>
                <PropertyStateLabel state={property.state} />
              </Stack>
              <Stack direction={'column'} px={4}>
                <Typography variant={'h6'}>
                  {property?.address?.street} {property?.address?.streetNumber}
                </Typography>
                <Typography variant={'body2'} mb={2}>
                  {property?.address?.postcode} {property?.address?.city}
                </Typography>
                <Button
                  startIcon={<EnergySavingsLeaf />}
                  variant={'contained'}
                  sx={{ maxWidth: '220px' }}
                  color={'success'}
                  mt={2}
                  onClick={() => router.get(`/dashboard/property/${property.id}`)}
                >
                  {trans('button.current_consumption')}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              px: 2,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'unset',
                  md: 'flex-end'
                },
                mx: { xs: 'auto', md: 1 },
                width: { xs: 'fit-content', md: 'unset' }
              }
            }}
          >
            {TABS.filter((tab) => !tab?.role || hasRole(auth.user, tab?.role)).map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>
        <Card
          sx={{
            mb: 3,
            maxHeight: 290,
            flexGrow: 1
          }}
        >
          <InfoView
            header={trans('property.info.header')}
            description={trans('dashboard.info.description')}
            tip={trans('dashboard.info.tip')}
            button={trans('dashboard.info.button')}
            onClick={() =>
              openModal('videoModal', <VideoModal src={'07_Immobilie.mp4'} controls={true} autoPlay={true} />, {
                maxWidth: 'lg'
              })
            }
          />
        </Card>
      </Stack>

      {currentTab === 'data' && <PropertyDetailPage />}
      {currentTab === 'units' && (
        <ApartmentListView defaultFilter={{ property: property.id }}
          defaultSorting={[{ id: 'number', desc: false }]}
          searchFields={['label']}
          searchPlaceholder={trans('apartment.property_search_placeholder')}
        />
      )}
      {hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN) && currentTab === 'owners' && (
        <OwnerListView defaultFilter={{ property: property.id, type: 'CURRENT_OWNER' }} />
      )}
      {hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN) && currentTab === 'managers' && (
        <ManagerListView defaultFilter={{ property: property.id, type: 'CURRENT_MANAGER' }} />
      )}
      {currentTab === 'tenants' && <TenantListView defaultFilter={{ property: property.id, type: 'CURRENT_TENANT' }} />}
      {currentTab === 'documents' && <DocumentListView defaultFilter={{ property: property.id }} />}
      {currentTab === 'devices' && <DeviceListView defaultFilter={{ property: property.id }} context={{ property }} />}
    </>
  )
}
