import { DialogActions, DialogTitle, Grid, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import DocumentTypes from '@/constants/DocumentTypes'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaFileDragDrop from '@/components/form/inertia-file-drag-drop'
import PropTypes from 'prop-types'

export default function DocumentCreateModal({ setRefresh, result = false, billing, property, apartment }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/documents/upload`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    defaultValues: {
      type: null,
      date: new Date(),
      file: null,
      propertyId: property ?? null,
      apartmentId: apartment ?? null,
      billing: billing ? billing.id : null,
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('documentCreate')
        setRefresh(new Date())
      },
      (e) => {
        if (e?.response?.data?.status === 422) return

        ToastCtl.show(trans('general.upload_error'), 'error')
      }
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('document.upload')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <Grid container direction={'row'} spacing={2}>
                <Grid item md={6} xs={12}>
                  <InertiaAutocomplete
                    name={'type'}
                    input={{
                      label: trans('field.document.type'),
                      required: true
                    }}
                    items={Object.values(DocumentTypes)
                      .filter((type) => result ? type === 'PROPERTY_HEATING_BILL' || type === 'APARTMENT_HEATING_BILL' : type !== 'PROPERTY_HEATING_BILL' && type !== 'APARTMENT_HEATING_BILL')
                      .map((type) => ({
                        id: type,
                        label: trans(`document.type.${type}`)
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InertiaDatePicker
                    name={'date'}
                    input={{
                      label: trans('field.date'),
                      required: true
                    }}
                  />
                </Grid>
              </Grid>
              <InertiaFileDragDrop
                name={'file'}
                input={{
                  multiple: false
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('documentCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

DocumentCreateModal.propTypes = {
  setRefresh: PropTypes.func.isRequired,
  property: PropTypes.string,
  apartment: PropTypes.string,
  result: PropTypes.bool,
  billing: PropTypes.object
}
