import { trans } from '@/utils/translation'
import { formatDate } from '@/utils/format'
import { avatar } from '@/utils/misc'
import Avatar from '@mui/material/Avatar'
import { AvatarGroup } from '@mui/material'
import { avatarGroupClasses } from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'

export const COLUMNS = [
  {
    accessorKey: 'name',
    header: trans('field.document.name'),
    size: 250,
    enableSorting: false
  },
  {
    accessorKey: 'documentType',
    header: trans('field.document.type'),
    Cell: ({ renderedCellValue }) => trans(`document.type.${renderedCellValue}`),
    size: 250,
    enableSorting: false
  },
  {
    accessorKey: 'createdAt',
    header: trans('field.date'),
    Cell: ({ renderedCellValue }) => formatDate(renderedCellValue, 'DD.MM.YYYY HH:mm'),
    size: 150,
    enableSorting: true
  },
  {
    accessorKey: 'id',
    header: trans('field.document.shared_with'),
    Cell: ({ row }) => {
      let sharedWith = []
      if (row.original.users?.length > 0) {
        row.original.users.forEach((user) => sharedWith.push(user))
      }

      return sharedWith?.length > 0 ? (
        <AvatarGroup
          renderSurplus={(surplus) => <Box sx={{ color: '#fff' }}>+{surplus.toString()}</Box>}
          max={4}
          sx={{
            display: 'inline-flex',
            [`& .${avatarGroupClasses.avatar}`]: {
              width: 24,
              height: 24,
              '&:first-of-type': {
                fontSize: 12
              }
            }
          }}
        >
          {sharedWith.map((person) => person?.id && <Avatar key={person.id} alt={person.name} src={avatar(person)} />)}
        </AvatarGroup>
      ) : (
        '-'
      )
    },
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.property.ALL') }
}
