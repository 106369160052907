import ListItemText from '@mui/material/ListItemText'
import { ListItem, ListItemIcon } from '@mui/material'
import Iconify from '@/components/iconify'
import { useTheme } from '@mui/material/styles'
import { useForm } from '@/hooks/use-form'
import { FormProvider } from '@/context/use-form-context'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import { trans } from '@/utils/translation'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import Avatar from '@mui/material/Avatar'
import { avatar, hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'

export default function DocumentManagerInvitedItem({ label, document, item, setRefresh }) {
  const theme = useTheme()
  const { auth } = usePage().props
  const methods = useForm({
    url: {
      method: 'PATCH',
      location: `/documents/${document.id}/share/remove`
    },
    defaultValues: {
      user: item.id
    }
  })
  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        setRefresh(new Date())
      },
      () => ToastCtl.show(trans('general.missing_permissions'), 'error')
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
      <form onSubmit={handleSubmit}>
        <ListItem
          sx={{
            px: 0,
            py: 1
          }}
        >
          <ListItemIcon>
            <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={avatar(item)} />
          </ListItemIcon>
          <ListItemText
            primary={label}
            secondary={'Person'}
            primaryTypographyProps={{ noWrap: true, typography: 'subtitle2' }}
            sx={{ flexGrow: 1, pr: 1 }}
          />
          <LoadingButton
            disabled={document.createdBy?.id !== auth.user.id && !hasRole(auth.user, Roles.ROLE_ADMIN)}
            size="small"
            loading={submitting}
            endIcon={
              <Iconify
                width={20}
                icon="solar:trash-bin-trash-bold"
                sx={{ ml: -1 }}
                color={
                  document.createdBy?.id !== auth.user.id && !hasRole(auth.user, Roles.ROLE_ADMIN)
                    ? theme.palette.grey[300]
                    : theme.palette.error.main
                }
              />
            }
            onClick={handleSubmit}
          ></LoadingButton>
        </ListItem>
      </form>
    </FormProvider>
  )
}

DocumentManagerInvitedItem.propTypes = {
  document: PropTypes.object,
  label: PropTypes.string,
  item: PropTypes.object,
  setRefresh: PropTypes.func
}
