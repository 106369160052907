import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Iconify from '@/components/iconify'
import DocumentCreateModal from '../document/document-create-modal'
import { useModalContext } from '../../context/use-modal-context'
import { useTheme } from '@mui/material/styles'
import { useBoolean } from '../../hooks/use-boolean'
import useRemoteTable from '../../hooks/useRemoteTable'
import Button from '@mui/material/Button'
import { usePage } from '@inertiajs/react'
import { formatDate } from '../../utils/format'
import TableRowAction from '../../components/table/table-row-action'
import DocumentUpdateModal from '../document/document-update-modal'
import TableConfirmDelete from '../../components/table/table-confirm-delete'
import DocumentTypes from '@/constants/DocumentTypes'

export default function BillingDetailResult() {
  const { billing } = usePage().props
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        header: trans('billing.detail.result.columns.description'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => trans(`document.type.${row?.documentType ?? DocumentTypes.TENANT_METER_PROTOCOL}`),
        header: trans('billing.detail.result.columns.type'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => formatDate(row.documentDate),
        header: trans('billing.detail.result.columns.date'),
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const { openModal } = useModalContext()
  const theme = useTheme()
  const confirmRow = useBoolean()
  const [file, setFile] = useState(null)

  const methods = useRemoteTable()
  const { globalFilter, pagination, setPagination, isLoading, rowCount, setRefresh } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('billing.detail.result.header')} </Typography>
        <Button
          variant="contained"
          color={'primary'}
          sx={{ m: 1, mr: 4 }}
          onClick={() =>
            openModal('documentCreate', <DocumentCreateModal setRefresh={setRefresh} result={true} billing={billing} />)
          }
        >
          + {trans('document.create')}
        </Button>
      </Stack>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => {
      return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
          <Iconify
            icon={'eva:download-outline'}
            onClick={() => (document.location.href = `/documents/${row.original.documentId}/download`)}
            sx={{ cursor: 'pointer', display: { sm: 'none', md: 'block' } }}
          />
          <TableRowAction
            row={row}
            width={180}
            items={[
              {
                content: (
                  <>
                    <Iconify icon={'eva:eye-outline'} />
                    <Typography variant={'body'}>{trans('button.show')}</Typography>
                  </>
                ),
                callback: () => {
                  window.open(`/documents/${row.original.documentId}/view`, '_blank')
                }
              },
              {
                content: (
                  <>
                    <Iconify icon={'eva:download-outline'} />
                    <Typography variant={'body'}>{trans('button.download')}</Typography>
                  </>
                ),
                callback: () => {
                  document.location.href = `/documents/${row.original.documentId}/download`
                }
              },
              {
                content: (
                  <>
                    <Iconify icon={'eva:share-outline'} />
                    <Typography variant={'body'}>{trans('button.share')}</Typography>
                  </>
                ),
                callback: () => {
                  openModal(
                    'documentUpdate',
                    <DocumentUpdateModal documentId={row.original.documentId} setRefresh={setRefresh} />
                  )
                }
              },
              {
                content: (
                  <>
                    <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
                    <Typography variant={'body'}>{trans('button.delete')}</Typography>
                  </>
                ),
                callback: () => {
                  setFile(row.original.documentId)
                  confirmRow.onTrue()
                }
              }
            ]}
          />
        </Stack>
      )
    },
    columns,
    data: billing?.documents,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showProgressBars: isLoading,
      showSkeletons: isLoading
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
      <TableConfirmDelete url={`/documents/${file}`} confirmRows={confirmRow} setValue={setFile} />
    </>
  )
}
