import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'

import Divider from '@mui/material/Divider'
import { usePage } from '@inertiajs/react'
import { formatDeviceLocation, formatDeviceRoom } from '@/utils/format'

export default function DeviceInfo() {
  const { device } = usePage().props

  const renderProperty = (
    <>
      <CardHeader title={trans('device.property.title')} />
      <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
        <Typography variant="body2">
          {device.property.address.street} {device.property.address.streetNumber}
        </Typography>
        <Typography variant="body2">
          {device.property.address.postcode} {device.property.address.city}
        </Typography>
        <Typography variant="body2">{trans(`country.${device.property.address.country}`)}</Typography>
      </Stack>
    </>
  )

  const renderUnit = (
    <>
      <CardHeader title={trans('device.unit.title')} />
      <Typography variant="body2" sx={{ p: 3 }}>
        {formatDeviceLocation(device)}
      </Typography>
    </>
  )

  const renderMeasuringPoint = (
    <>
      <CardHeader title={trans('device.measuring_point.title')} />
      <Typography variant="body2" sx={{ p: 3 }}>
        {formatDeviceRoom(device)}
      </Typography>
    </>
  )

  return (
    <Card>
      {renderProperty}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderUnit}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderMeasuringPoint}
    </Card>
  )
}
