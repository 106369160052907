import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import { ApartmentListView } from '../../sections/apartment/view'
import ApartmentState from '@/constants/ApartmentState'

const ApartmentListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('apartment.title')}</title>
      </Head>
      <ApartmentListView
        defaultFilter={{ state: ApartmentState.DONE }}
        searchFields={['label', 'street_number']}
        searchPlaceholder={trans('apartment.search_placeholder')}
      />
    </>
  )
}
ApartmentListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default ApartmentListPage
