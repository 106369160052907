import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import InertiaTextfield from '../../components/form/inertia-textfield'
import InertiaDatePicker from '../../components/form/inertia-date-picker'
import InertiaSwitch from '../../components/form/inertia-switch'

export default function PropertyCompleteStep2() {
  const { property } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-3`
    },
    defaultValues: {
      apartments: property.apartments.map((apartment) => ({
        id: apartment.id,
        begin: apartment.tenant?.user?.begin || dayjs().subtract(1, 'year').startOf('year').set('hour', 12),
        isEmpty: (apartment.tenant && !apartment.tenant.user) || false,
        name: apartment.tenant?.user?.name,
        email: apartment.tenant?.user?.email
      }))
    }
  })

  const { submit, submitting, data, setData, setOverrideFieldValues, setError } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-4`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '750px', mx: 'auto' }}>
          <CardHeader sx={{ mb: 2 }} title={trans('initial_setup.tenant_info.header')} />
          {property.apartments.map((apartment, index) => (
            <Box key={index}>
              <Typography variant={'body2'} sx={{ ml: 3, mt: 2, mb: 1 }}>
                {apartment.label}
              </Typography>

              <Stack direction="column" spacing={{ xs: 2 }} sx={{ mx: 3 }}>
                <Box sx={{ maxWidth: '170px' }}>
                  <InertiaSwitch
                    name={`apartments.${index}.isEmpty`}
                    input={{
                      label: trans('apartment_tenant.is_empty')
                    }}
                    callback={(value) => {
                      if (value) {
                        setData(`apartments.${index}.name`, null, false, false)
                        setData(`apartments.${index}.email`, null, false, false)

                        setOverrideFieldValues({
                          [`apartments.${index}.name`]: null,
                          [`apartments.${index}.email`]: null
                        })

                        setError(`apartments.${index}.name`, null)
                        setError(`apartments.${index}.email`, null)
                      } else {
                        // Reset override, so on next change we can reset name again
                        setOverrideFieldValues({
                          [`apartments.${index}.name`]: undefined,
                          [`apartments.${index}.email`]: undefined
                        })
                      }
                    }}
                  />
                </Box>
                <Stack direction={{ md: 'row', sm: 'column' }} spacing={{ xs: 2 }}>
                  <InertiaDatePicker
                    name={`apartments.${index}.begin`}
                    input={{
                      label: trans('initial_setup.tenant_info.begin'),
                      required: true,
                      disabled: data.apartments[index].isEmpty
                    }}
                  />
                  <InertiaTextfield
                    name={`apartments.${index}.name`}
                    input={{
                      label: trans('initial_setup.tenant_info.tenant'),
                      required: true,
                      disabled: data.apartments[index].isEmpty
                    }}
                  />
                  <InertiaTextfield
                    name={`apartments.${index}.email`}
                    input={{
                      label: trans('initial_setup.tenant_info.email'),
                      disabled: data.apartments[index].isEmpty
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          ))}
          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => router.get(`/properties/${property.id}/complete/step-2`)}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('button.next')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}
