import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { FormControl, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { STATUS_OPTIONS } from './apartment-table-definitions'
import Tab from '@mui/material/Tab'
import Label from '../../../components/label'
import Button from '@mui/material/Button'
import { useStateCounts } from '@/hooks/use-state-counts'
import PropTypes from 'prop-types'
import ApartmentCreateModal from '@/sections/apartment/apartment-create-modal'
import { useModalContext } from '@/context/use-modal-context'
import ListFilterTextfield from '@/components/form/list-filter-textfield'
import { ToastCtl } from '@/utils/toast-ctl'

export default function ApartmentTableToolbar({
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  resetAll,
  url,
  searchPlaceholder
}) {
  const popover = usePopover()
  const { openModal } = useModalContext()
  const counts = useStateCounts(`/apartments/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({ ...columnFilters, state: value })
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(([key, { color, label }]) => (
            <Tab
              key={key}
              iconPosition="end"
              value={key}
              label={label}
              icon={
                <Label variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'} color={color}>
                  {counts[key] ?? counts['ALL']}
                </Label>
              }
            />
          ))}
        </Tabs>
        {columnFilters.property && (
          <Button
            variant="contained"
            color={'primary'}
            sx={{ m: 1, mr: 4 }}
            onClick={() => openModal('apartmentCreate', <ApartmentCreateModal propertyId={columnFilters.property} />)}
          >
            + {trans('apartment.create')}
          </Button>
        )}
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              flexGrow: 1,
              width: { xs: 1, md: 200 }
            }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <ListFilterTextfield
                placeholder={searchPlaceholder}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </FormControl>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 220 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
          {columnFilters.property && (
            <MenuItem
              onClick={() => {
                openModal('apartmentCreate', <ApartmentCreateModal propertyId={columnFilters.property} />)
                popover.onClose()
              }}
            >
              <Iconify icon={'carbon:add'} sx={{ width: 24, height: 24, mr: '16px' }} />
              {trans('apartment.create')}
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              ToastCtl.show(trans('general.data_export_hint'), 'info')

              url.pathname = url.pathname.replace('list', 'export')
              document.location.href = url.href

              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:download'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('button.csv_export')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

ApartmentTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refresh: PropTypes.number,
  resetAll: PropTypes.func,
  url: PropTypes.object,
  searchPlaceholder: PropTypes.string
}
