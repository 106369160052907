import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import dayjs from 'dayjs'

export default function BillingCreateModal() {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/api/billings`
    },
    defaultValues: {
      property: '',
      begin: dayjs().startOf('year').endOf('day'),
      end: dayjs().endOf('year')
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/billings/${response.data.id}/detail`)
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('billingCreate')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`billing.create`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <InertiaRemoteAutocomplete
                name={'property'}
                input={{
                  label: trans('general.property'),
                  required: true,
                  tooltip: trans('general.not_all_properties', { count: 30 })
                }}
                url={'/api/properties?label=%s&itemsPerPage=30'}
              />
              <Stack direction={'row'} spacing={2}>
                <InertiaDatePicker
                  name={'begin'}
                  input={{
                    label: trans('general.from'),
                    required: true
                  }}
                />
                <InertiaDatePicker
                  name={'end'}
                  input={{
                    label: trans('general.to'),
                    required: true
                  }}
                />
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('billingCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}
