import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import { Card, CardHeader, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import CustomLabelValue from '../../components/custom-label-value'
import UserState from '@/constants/UserState'
import UserEditModal from '@/sections/user/user-edit-modal'
import { useModalContext } from '@/context/use-modal-context'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ToastCtl } from '@/utils/toast-ctl'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function UserData() {
  const { user } = usePage().props
  const { openModal } = useModalContext()
  const request = useSingletonAxios()

  const appAccess = user.state === UserState.ACTIVE || user.state === UserState.INVITED

  const renderInviteUser = (
    <Box sx={{ mt: 1 }}>
      <Button
        variant={'outlined'}
        onClick={() =>
          request({
            method: 'POST',
            url: `/user/${user.id}/invite`
          })
            .then(() => ToastCtl.show(trans('user.re_invite.sent'), 'success'))
            .catch(() => ToastCtl.show(trans('user.re_invite.failed'), 'error'))
        }
      >
        {trans('user.re_invite')}
      </Button>
    </Box>
  )

  return (
    <>
      <Card>
        <CardHeader
          title={trans('user.data.title')}
          action={
            <IconButton onClick={() => openModal('userDataEdit', <UserEditModal user={user} />)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          }
        />
        <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
          {user.company && <Typography variant="body2">{user.company}</Typography>}
          <Typography variant="body2">{user.name}</Typography>
          <Typography variant="body2">
            {user.address?.street} {user.address?.streetNumber}
          </Typography>
          <Typography variant="body2">
            {user.address?.postcode} {user.address?.city}
          </Typography>
          <Typography variant="body2">{user.email}</Typography>
          <Typography variant="body2">{user.phone}</Typography>
          <CustomLabelValue
            label={trans('user.data.assetapp_access')}
            value={appAccess ? trans('general.yes') : trans('general.no')}
          />

          {appAccess && renderInviteUser}
        </Stack>
      </Card>
    </>
  )
}
