import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { FormControl, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { STATUS_OPTIONS } from './document-table-definitions'
import Tab from '@mui/material/Tab'
import Label from '../../../components/label'
import Button from '@mui/material/Button'
import { useStateCounts } from '@/hooks/use-state-counts'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import DocumentTypes from '../../../constants/DocumentTypes'
import Autocomplete from '@mui/material/Autocomplete'
import PropTypes from 'prop-types'
import DocumentCreateModal from '@/sections/document/document-create-modal'
import { useModalContext } from '@/context/use-modal-context'
import ListFilterTextfield from '@/components/form/list-filter-textfield'

export default function DocumentTableToolbar({
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  resetAll,
  setRefresh
}) {
  const popover = usePopover()
  const { openModal } = useModalContext()
  const counts = useStateCounts(`/documents/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)
  const documentFilterTypes = Object.values(DocumentTypes)
    .map((type) => ({
      id: type,
      label: trans(`document.type.${type}`)
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({ ...columnFilters, state: value })
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(([key, { color, label }]) => (
            <Tab
              key={key}
              iconPosition="end"
              value={key}
              label={label}
              icon={
                <Label variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'} color={color}>
                  {counts[key] ?? counts['ALL']}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Button
          variant="contained"
          color={'primary'}
          sx={{ m: 1, mr: 4 }}
          onClick={() =>
            openModal('documentCreate', <DocumentCreateModal setRefresh={setRefresh} {...columnFilters} />)
          }
        >
          + {trans('document.create')}
        </Button>
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <DatePicker
            label="Datum von"
            format={'DD.MM.YYYY'}
            value={columnFilters.begin ? dayjs(columnFilters.begin) : null}
            onChange={(value) => setColumnFilters({ ...columnFilters, begin: value })}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setColumnFilters({ ...columnFilters, begin: null })
                }
              }
            }}
            sx={{
              maxWidth: { md: 200 }
            }}
          />
          <DatePicker
            label="Datum bis"
            format={'DD.MM.YYYY'}
            value={columnFilters.end ? dayjs(columnFilters.end) : null}
            onChange={(value) => setColumnFilters({ ...columnFilters, end: value })}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setColumnFilters({ ...columnFilters, end: null })
                }
              }
            }}
            sx={{
              maxWidth: { md: 200 }
            }}
          />
          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 250 }
            }}
          >
            <Autocomplete
              value={columnFilters?.types ? documentFilterTypes.find((item) => item.id === columnFilters.types) : null}
              defaultValue={
                columnFilters?.types ? documentFilterTypes.find((item) => item.id === columnFilters.types) : null
              }
              onChange={(e, item) =>
                setColumnFilters({
                  ...columnFilters,
                  types: item?.id
                })
              }
              renderInput={(params) => <TextField {...params} label={trans('field.document.type')} />}
              options={documentFilterTypes}
            ></Autocomplete>
          </FormControl>
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              flexGrow: 1,
              width: { xs: 1, md: 200 }
            }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <ListFilterTextfield
                placeholder={trans('document.search_placeholder')}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </FormControl>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 220 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              openModal('documentCreate', <DocumentCreateModal setRefresh={setRefresh} {...columnFilters} />)
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:document'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('document.create')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

DocumentTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refresh: PropTypes.number,
  setRefresh: PropTypes.func,
  resetAll: PropTypes.func
}
