import { trans } from '@/utils/translation'
import { Card, CardHeader, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import InertiaTextField from '@/components/form/inertia-textfield'
import { FormProvider } from '@/context/use-form-context'
import useSubmit from '@/hooks/use-submit'
import { useForm } from '@/hooks/use-form'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'

export default function PropertyCompleteStep4() {
  const { property } = usePage().props
  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-4`
    },
    defaultValues: {
      name: property.contactPerson?.name ?? '',
      email: property.contactPerson?.email ?? '',
      phone: property.contactPerson?.phone ?? ''
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-5`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '400px', mx: 'auto' }}>
          <CardHeader title={trans('initial_setup.contact_person.header')} />
          <Typography variant={'body2'} sx={{ ml: 3 }}>
            {trans('initial_setup.contact_person.description')}
          </Typography>
          <Stack direction={'column'} spacing={2} sx={{ mx: 3, my: 2 }}>
            <InertiaTextField
              name={'name'}
              input={{
                label: trans('initial_setup.contact_person.name'),
                required: true
              }}
            />
            <InertiaTextField
              name={'email'}
              input={{
                label: trans('initial_setup.contact_person.email'),
                required: true
              }}
            />
            <InertiaTextField
              name={'phone'}
              input={{
                label: trans('initial_setup.contact_person.phone'),
                required: true
              }}
            />
          </Stack>
          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => router.get(`/properties/${property.id}/complete/step-3`)}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('button.next')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}
